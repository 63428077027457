import React, { useState } from "react";
import Upload from "../../../assets/upload.png";
import EmptyFolder from "../../../assets/Empty Folder.png";
import Xls from "../../../assets/XLS.png";
// import Loader from "../../../assets/Mask Group 6.svg";
import { VscChromeClose } from "react-icons/vsc";
import * as XLSX from "xlsx";
import toast from 'react-hot-toast';
import "react-toastify/dist/ReactToastify.css";
import Papa from "papaparse";
import axios from "axios";
import CsvPreview from "../../ReUseable/CsvPreview";
import SheetLists from "../../ReUseable/SheetLists";
import WorkflowFilePreview from "./WorkflowFilePreview";
import loadingGif from "../../../assets/loading.gif";
import DataValidation from "../DataValidation/DataValidation";
import MappingTable from "../Mapping/MappingTable";
import { useAuth } from "../../../hooks/useAuth";
import { API } from "../../../global";
import { BlobServiceClient } from '@azure/storage-blob';
import { v4 as uuid } from 'uuid';
// import * as ExcelJS from 'exceljs';

const Preview = ({ setPreviewStepper, setDataValidationStepper, setAutoMappingStepper, setReviewStepper, setTargetStepper, dataValidationStepper, setDestinationStepper, setDeleteModalOpen, deleteModalOpen }) => {
  const [sname, setSname] = useState(false);
  const [sheet, setSheet] = useState([]);
  const [sheetData, setSheetData] = useState(null);
  const [SheetNames, setSheetNames] = useState(null);
  const [file_type, setFile_type] = useState("");
  const [file_name, setFile_name] = useState("");
  const acceptableFileName = ["xlsx", "xls", "csv"];
  // const [singleSheet, setSingleSheet] = useState(false);
  const [sheetPreview, setSheetPreview] = useState(false);
  //csv
  const [csvTable, setCsvTable] = useState(false);
  const [values, setValues] = useState([]);
  const [tableRows, setTableRows] = useState([]);
  const [tableShow, setTableShow] = useState(false);

  const [checkAll, setCheckAll] = useState(false);
  const [checkboxes, setCheckboxes] = useState([]);
  const [selectedValues, setSelectedValues] = useState([]);
  const [skipDataValidation, setSkipDataValidation] = useState(false);
  const [noFileSelected, setNoFileSelected] = useState(true);
  const [fileName, setFileName] = useState(false);

  const [currentIndex, setCurrentIndex] = useState(0);

  const [loading, setLoading] = useState(false);
  const [dataValidation, setDataValidation] = useState(false);
  const [autoMapping, setAutoMapping] = useState(false);
  const [review, setReview] = useState(false);
  // const [mappingResponse, setMappingResponse] = useState([]);
  const [excelFile, setExcelFile] = useState();
  const { user } = useAuth();

  const handleForwardClick = () => {
    if (currentIndex < selectedValues.length - 1) {
      setCurrentIndex(currentIndex + 1);
    }
  };

  const handleBackwardClick = () => {
    if (currentIndex > 0) {
      setCurrentIndex(currentIndex - 1);
    }
  };

  const displayedElements = selectedValues.slice(
    currentIndex,
    currentIndex + 7
  );

  const handleCheckboxChange = (value, checked) => {
    if (checked) {
      setCheckboxes([...checkboxes, value]);
    } else {
      setCheckboxes(checkboxes.filter((item) => item !== value));
    }
  };

  const handleSubmit = () => {
    if (checkboxes.length === SheetNames.length) {
      setSelectedValues(checkboxes);
    } else {
      setSelectedValues(checkboxes);
    }
    // setSingleSheet(true);
    setSheetPreview(true);
    setTableShow(true);
    setSname(false);
    setSheet(checkboxes[0]);
  };

  const handleFileUpload = async (e) => {
    localStorage.removeItem("validationGroupNameAllSheets");
    for (var key in localStorage) {
      if (key.startsWith('validationGroupName-')) {
        localStorage.removeItem(key);
      }
    }
    localStorage.removeItem("applyValidationToAllSheets");
    setNoFileSelected(false);
    setLoading(true);
    const file = e.target.files[0];
    // setting file name
    const fileName = file.name;
    setFile_name(fileName);
    //setting file type
    const ft = fileName.split(".").pop().toLowerCase();
    setFile_type(ft);
    setFileName(true);
    if (!acceptableFileName.includes(ft)) {
      toast.warning("Invalid File Type");
      return;
    }
    if (ft === "csv") {
      Papa.parse(file, {
        header: true,
        skipEmptyLines: true,
        complete: async function (results) {
          // setCsvData(results.data);
          setCsvTable(true);
          setSname(false);
          const rowsArray = [];
          const valuesArray = [];
          // Iterating data to get column name and their values
          results.data.map((d) => rowsArray.push(Object.keys(d)));
          results.data.map((d) => valuesArray.push(Object.values(d)));
          // Filtered Column Names
          setTableRows(rowsArray[0]);
          localStorage.setItem("header", JSON.stringify(rowsArray[0]));
          // Filtered Values
          setValues(valuesArray);
          localStorage.setItem("filename", JSON.stringify(fileName));
          const csvF = Papa.unparse(results.data);
          const upload = new Blob([csvF], { type: "text/csv;charset=utf-8;" });
          upload.name = fileName;
          const formData = new FormData();
          formData.append("file", upload, fileName);
          formData.append("uuid", JSON.stringify(user.current_workspace.workspace_unique_id));
          try {
            const response = await axios
              .post(`${API}/ai-api/predict`, formData, {
                "Content-Type": "multipart/form-data",
              })
            console.log(response.data);
            localStorage.setItem("Api_2", JSON.stringify(response.data));
            setLoading(false);
            setPreviewStepper(true);
          }
          catch (error) {
            toast('It appears that there is an error in the backend API. Please ensure that you have prepared the system before proceeding with your workflow.');
            clearStates();
          }
        },
      });
      setCsvTable(true);
    }

    if (ft === "xls" || ft === "xlsx") {
      // Convert Excel file to JSON    
      setExcelFile(file);
      const data = await file.arrayBuffer();
      const wb = XLSX.read(data);
      setSheetNames(wb.SheetNames);
      try {
        let mySheetData = {};
        for (let j = 0; j < wb.SheetNames.length; j++) {
          let sheetName = wb.SheetNames[j];
          const worksheet = wb.Sheets[sheetName];
          const jsonData = XLSX.utils.sheet_to_json(worksheet, {
            blankrows: "",
            header: 1,
            defval: "",
            cellDates: true,// Enable date parsing            
            raw: false
          });
          mySheetData[sheetName] = jsonData.map(row => {
            // Convert date string to Date object for all date values
            for (const key in row) {
              if (Object.prototype.hasOwnProperty.call(row, key) && row[key] instanceof Date) {
                row[key] = new Date(row[key]);
              }
            }
            return row;
          });
        }
        setSheetData(mySheetData);
        setPreviewStepper(true);
        setSname(true);
        setLoading(false);
      }
      catch (error) {
        console.error("Error processing the file:", error);
      }
    }
  };

  const clearStates = () => {
    setSname(false);
    setNoFileSelected(true);
    setSheetPreview(false);
    setFileName(false);
    setReview(false);
    // setCsvData([]);
    setCsvTable(false);
    setTableShow(false);
    setCheckboxes([]);
    setCheckAll(false);
    setDataValidation(false);
    setAutoMapping(false);
    setSkipDataValidation(false);
    setPreviewStepper(false);
    setDataValidationStepper(false);
    setAutoMappingStepper(false);
    setReviewStepper(false);
    setDestinationStepper(false);
  };

  const handleSingleSheet = (e) => {
    setSheetPreview(true);
    setTableShow(true);
    setSname(false);
    setFileName(false);
  };

  const handleDataValidationPreview = async () => {
    setFileName(false);
    setSname(false);
    setTableShow(false);
    setSheetPreview(false);
    setCheckAll(false);
    // if data validation is skipped call predict API directly
    if (skipDataValidation) {
      setDataValidationStepper(true);
      setAutoMappingStepper(true);
      setAutoMapping(true);
      handleMapping();
    }
    else {
      setDataValidation(true);
    }
  };

  const handleSelectAll = (checked) => {
    setCheckAll(!checkAll);
    if (checked) {
      setCheckboxes(SheetNames);
    } else {
      setCheckboxes([]);
    }
  };

  //upload files to Azure Storage 
  const uploadFile = async (myFile) => {
    const blobServiceClient = new BlobServiceClient(`${localStorage.getItem('sas_url')}`);
    const containerName = user?.current_workspace._id.toString().toLowerCase();
    const containerClient = blobServiceClient.getContainerClient(containerName);
    // Upload the file
    const options = {
      blobHTTPHeaders: {
        blobContentType: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
      }
    };

    try {
      // Generate a unique filename using UUID
      const fileName = uuid() + ".xlsx";
      const blockBlobClient = containerClient.getBlockBlobClient(fileName);
      await blockBlobClient.uploadData(myFile, options);
      return blockBlobClient.url;
    } catch (error) {
      console.error('Error uploading File:', error);
    }
  };

  const handleMapping = async () => {
    setDataValidation(false);
    setDataValidationStepper(true);
    setAutoMappingStepper(true);
    setDeleteModalOpen(false);
    try {
      setAutoMapping(true);
      const fileUrl = await uploadFile(excelFile);
      const fileDetails = {
        url: fileUrl,
        uuid: user?.current_workspace?.workspace_unique_id
      }
      const config = {
        headers: {
          "Content-Type": "application/json",
          authorization: `Bearer ${localStorage.getItem("auth_token")}`
        }
      };
      const res = await axios.post(`${API}/ai-api/predict`, fileDetails, config);
      if (res.data.success) {
        localStorage.setItem(`MAPPING_API_RES`, JSON.stringify(res.data.result));
        setAutoMapping(false);
        setReview(true);
        setReviewStepper(true);
      } else {
        toast.error(res.data.error.msg);
        if (skipDataValidation) {
          setSheetPreview(true);
          setTableShow(true);
          setAutoMapping(false);
        } else {
          setAutoMapping(false);
          setDataValidation(true);
          setDataValidationStepper(false);
          setAutoMappingStepper(false);
        }
      }
    } catch (error) {
      toast.error('It appears that there is an error in the backend API. Please ensure that you have prepared the system before proceeding with your workflow.');
      clearStates();
    }
  }

  console.log(sheetData, 'sheet data');

  return (
    <>

      <div className="my-10 flex gap-[25px]">
        {tableShow ? (
          <div className="flex flex-col">
            <div className="font-medium text-lg">Upload</div>
            <div className="z-10">
              <div className="flex mt-5 justify-between p-5 h-20 gap-[90px] bg-white rounded-lg hover:w-fit drop-shadow-[0_0_10px_#00000029] overflow-hidden transition-all w-20 delay-[200ms]">
                <div className='flex gap-5 items-center'>
                  <img src={Xls} alt="xls" className='w-10 h-10' />
                  <div className="flex flex-col text-xs text-lite-black font-semibold">
                    {file_name}
                    <span>{`text/${file_type}`}</span>
                  </div>
                </div>
                <div className='flex items-center' onClick={() => clearStates()}>
                  <VscChromeClose color="#707070" fontSize="15px" className="cursor-pointer" />
                </div>
              </div>
            </div>
          </div>
        ) : ''}
        {fileName ? (
          <div className="flex flex-col">
            <div className="font-medium text-lg">Upload</div>
            <div className="p-5 flex items-center gap-9 bg-white rounded-lg shadow-[0px_0px_10px_0px_rgba(0,0,0,0.16)] font-semibold mt-5 text-lite-black text-xs z-10">
              <span>
                <img src={Xls} alt="xls" className='w-10 h-10 object-cover' />
              </span>
              <div className="flex flex-col text-xs">
                {file_name}
                <span>{`text/${file_type}`}</span>
              </div>
              <span className="ml-10" onClick={() => clearStates()}>
                <VscChromeClose color="#707070" fontSize="15px" className='cursor-pointer' />
              </span>
            </div>
          </div>
        ) : ""}

        {noFileSelected ?
          <div className="flex flex-col font-medium text-lg">
            <div className="mb-5">Upload</div>
            <div className="flex flex-col items-center border-dashed border border-lite-grey-5 rounded-xl py-[30px] px-[45px]">
              <label htmlFor="files" className="flex justify-center mb-2 cursor-pointer">
                <img src={Upload} alt="upload" />
              </label>
              <input
                id="files"
                type="file"
                accept=".csv,.xlsx,.xls"
                onChange={(e) => handleFileUpload(e)}
                className="hidden" />
              <div className="mb-1">Click to upload</div>
              <div className="font-regular text-xs">
                Please upload .xlsx or .csv file. The maximum size of file is
                1 GB
              </div>
            </div>
          </div>
          : ""}

        <div className="flex-1 font-medium text-lg">
          {loading ?
            <div className="px-5 border-l-[1px] border-[#CCCCCC] h-[280px]">
              <div>Preview</div>
              <div className="flex justify-center items-center mt-10 flex-col">
                <div className="flex flex-col justify-center items-center">
                  <svg xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" width="50" height="65" viewBox="0 0 50 65">
                    <defs>
                      <clipPath id="clip-path">
                        <path id="Path_15" data-name="Path 15" d="M95.487,19.043A4.471,4.471,0,0,1,91,14.6V0H68.051A7.025,7.025,0,0,0,61,6.982V58.018A7.025,7.025,0,0,0,68.051,65h35.9A7.025,7.025,0,0,0,111,58.018V19.043ZM71.513,45.7h9.323a1.9,1.9,0,1,1,0,3.809H71.513a1.9,1.9,0,1,1,0-3.809ZM69.59,37.451a1.914,1.914,0,0,1,1.923-1.9H99.718a1.9,1.9,0,1,1,0,3.809H71.513A1.914,1.914,0,0,1,69.59,37.451ZM99.718,25.391a1.9,1.9,0,1,1,0,3.809H71.513a1.9,1.9,0,1,1,0-3.809Z" transform="translate(857 340)" fill="#bcbcbc" />
                      </clipPath>
                      <linearGradient id="linear-gradient" x1="0.067" y1="1" x2="1" gradientUnits="objectBoundingBox">
                        <stop offset="0" stopColor="#00c8ad" />
                        <stop offset="1" stopColor="#0078c5" />
                      </linearGradient>
                    </defs>
                    <g id="Mask_Group_6" data-name="Mask Group 6" transform="translate(-918 -340)" clipPath="url(#clip-path)">
                      <rect id="Rectangle_105" data-name="Rectangle 105" width="50" height="65" transform="translate(918 340)" fill="url(#linear-gradient)" />
                    </g>
                  </svg>
                  {/* <img src={loadingGif} alt="loading" className="w-[50px] h-[65px]" /> */}
                  {/* <img src={Loader} alt="loader" /> */}
                  <h5 className="mt-5">Analysing....</h5>
                </div>
              </div>
            </div>
            : ""}

          {csvTable ?
            <CsvPreview
              tableRows={tableRows}
              values={values}
              file_name={file_name}
            />
            : ""}

          {sname ? (
            <div>
              <SheetLists checkboxes={checkboxes} handleSubmit={handleSubmit}
                handleSingleSheet={handleSingleSheet} handleSelectAll={handleSelectAll}
                handleCheckboxChange={handleCheckboxChange} setSheet={setSheet} sheetNames={SheetNames}
                checkAll={checkAll} setSheetPreview={setSheetPreview} setSname={setSname} excel={true} />
            </div>
          ) : ""}

          {sheetPreview ?
            <div className="z-0 absolute left-44 right-5 pb-5">
              <WorkflowFilePreview handleDataValidationPreview={handleDataValidationPreview} setCheckboxes={setCheckboxes} sheet={sheet} sheetData={sheetData} selectedValues={selectedValues} checkboxes={checkboxes} setSheet={setSheet} displayedElements={displayedElements} handleBackwardClick={handleBackwardClick} currentIndex={currentIndex} handleForwardClick={handleForwardClick} skipDataValidation={skipDataValidation} setSkipDataValidation={setSkipDataValidation} setSheetPreview={setSheetPreview} setSname={setSname} setPreviewStepper={setPreviewStepper} setTableShow={setTableShow} checkAll={checkAll} setCheckAll={setCheckAll} setFileName={setFileName} sheetNames={SheetNames} />
            </div>
            : ""}

          {dataValidation ?
            <DataValidation sheet={sheet} setDataValidationStepper={setDataValidationStepper}
              clearStates={clearStates} sheetData={sheetData} fileName={file_name} excelFile={excelFile}
              handleMapping={handleMapping} deleteModalOpen={deleteModalOpen} setDeleteModalOpen={setDeleteModalOpen}
              selectedValues={selectedValues} currentIndex={currentIndex} setSheet={setSheet} sheetNames={SheetNames}
              setDataValidation={setDataValidation} setSheetPreview={setSheetPreview} displayedElements={displayedElements}
              setTableShow={setTableShow} handleBackwardClick={handleBackwardClick} handleForwardClick={handleForwardClick} checkboxes={checkboxes} />
            : ''}

          {autoMapping ?
            <div className="flex justify-center items-center mt-28 flex-col">
              <div className="flex flex-col justify-center items-center">
                <img src={loadingGif} alt="loading" className="w-[50px] h-[65px]" />
                <h5 className="mt-5">Analysing....</h5>
              </div>
            </div> : ''}

          {review ?
            <MappingTable clearStates={clearStates} sheet={sheet} sheetData={sheetData} selectedValues={selectedValues}
              checkboxes={checkboxes} setSheet={setSheet} displayedElements={displayedElements} handleBackwardClick={handleBackwardClick}
              currentIndex={currentIndex} setDataValidation={setDataValidation} setAutoMappingStepper={setAutoMappingStepper}
              handleForwardClick={handleForwardClick} setReview={setReview} setReviewStepper={setReviewStepper}
              sheetNames={SheetNames} setDataValidationStepper={setDataValidationStepper}
              setSkipDataValidation={setSkipDataValidation} setDeleteModalOpen={setDeleteModalOpen} excelFile={excelFile}
              setDestinationStepper={setDestinationStepper} />
            : ""}

          {noFileSelected ?
            <div className="px-5 border-l-[1px] border-[#CCCCCC] h-[280px]">
              <div>Preview</div>
              <div className="mt-2 flex flex-col">
                <div className="flex flex-col items-center p-5">
                  <div className="flex justify-center mb-2 w-fit">
                    <img src={EmptyFolder} alt="folder" />
                  </div>
                  <div className="mb-1">No data file has been selected</div>
                  <div className="text-[11px]">
                    To preview data specific data you would like to ingest
                    into a dataset within platform
                  </div>
                </div>
              </div>
            </div> : ''}
        </div>
      </div>

      {noFileSelected ?
        user?.current_workspace?.is_trained ?
          <></>
          :
          <div className="bg-[#D4F1FF] border border-teal rounded-xl p-5 my-10 mx-12 text-lite-black">
            <div className="text-lg font-medium">Preparation</div>
            <div className="text-sm font-normal mt-2.5">
              {user?.current_workspace?.is_train_data_uploaded && !user?.current_workspace?.is_trained ?
                <>
                  Your train data is uploaded. Training is in progress. Data validation and Auto Mapping accuracy will improve once training is completed.
                </> :
                <>
                  You have not trained any target data. Data validation and Auto Mapping accuracy will improve once training is completed.
                </>}
            </div>
          </div> : ''}
    </>
  );
};

export default Preview;
