import React, { useEffect, useState } from 'react';
import remove from "../../../assets/Remove.svg";
import bulb from "../../../assets/bulb.svg";
// import bulbNotify from "../../assets/bulbNotify.svg";
// import { useDrag, useDrop } from 'react-dnd';
// import { uuid } from 'uuidv4';
import { v4 as uuid } from 'uuid';
import { API } from '../../../global';
import axios from "axios";
import LeftArrow from "../../../assets/leftArrow.svg";
import RightArrow from "../../../assets/rightArrow.svg";
import disableLeft from "../../../assets/disableLeft.svg";
import disableRight from "../../../assets/disableRight.svg";
import downArrow from "../../../assets/downArrow.svg";
// import { PiArrowRightBold } from "react-icons/pi";
import toast from 'react-hot-toast';
// import backWhite from "../../../assets/backWhite.svg";
import Papa from "papaparse";
import Success from '../../ReUseable/Success/Success';
import loadingGif from "../../../assets/loading.gif";
import { BlobServiceClient } from '@azure/storage-blob';
import { useAuth } from '../../../hooks/useAuth';

const MappingTable = ({ sheet, sheetData, clearStates, setSheet, checkboxes,
  selectedValues, currentIndex, displayedElements, handleBackwardClick,
  handleForwardClick, handleBack, sheetNames, setDataValidation, dataValidation,
  setReview, setReviewStepper, setAutoMappingStepper, setDataValidationStepper,
  setSkipDataValidation, setDeleteModalOpen, setDestinationStepper, excelFile }) => {
  const [dropdownVisible, setDropdownVisible] = useState(false);
  // const [mappingDropdownVisible, setMappingDropdownVisible] = useState(false);
  const [sourceTargetMappedArr, setSourceTargetMappedArr] = useState([]);
  const [unmappedTargetFields, setUnmappedTargetFields] = useState([]);
  const [airbyteLoading, setAirbyteLoading] = useState(false);
  const [success, setSuccess] = useState(false);
  const [mappingScreen, setMappingScreen] = useState(true);
  const { user, updateUser } = useAuth();

  let source_target_mapped_arr = [];

  useEffect(() => {
    setSheet(selectedValues[0]);
    // eslint-disable-next-line
  }, [])

  useEffect(() => {
    let updatedTargetArr = [];
    if (sheetNames.length > 1) {
      const predictResponse = JSON.parse(localStorage.getItem(`MAPPING_API_RES`));
      // grouping the predicted column names from the API response for the selected sheet by the column names
      for (let i = 0; i < sheetData[sheet][0]?.length; i++) {
        let targetArr = predictResponse?.filter((res) => `${res.source_label}` === `${sheet}.${sheetData[sheet][0][i]}`)
        const prepareSheetNames = JSON.parse(localStorage.getItem('prepare-sheetNames'));
        // Removing the sheet names from the column names in the predicted label
        if (prepareSheetNames) {
          updatedTargetArr = targetArr?.map((obj) => {
            // Use regex to replace the sheet names in predicted_label
            obj.predicted_label = obj.predicted_label.replace(new RegExp(`^(${prepareSheetNames.join('|')})\\.`), '');
            return obj;
          });
        } else {
          updatedTargetArr = targetArr?.map((obj) => {
            // Split the predicted label by dot
            const labelParts = obj.predicted_label.split('.');
            // Get the last part which is the column name
            const columnName = labelParts[labelParts.length - 1];
            // Return the updated object with only column name
            return {
              ...obj,
              predicted_label: columnName
            };
          });
        }
        const targetArrSorted = updatedTargetArr?.sort((a, b) => b?.prob_score - a?.prob_score);
        source_target_mapped_arr.push({
          id: uuid(),
          source_field: sheetData[sheet][0][i],
          target_field: targetArrSorted ? targetArrSorted[0] : {},
          mapped: targetArrSorted?.length < 1 ? false : true,
          target_field_arr: targetArrSorted,
          mappingRecommendations: false
        })
      }
      setSourceTargetMappedArr(source_target_mapped_arr);
    } else {
      const predictResponse = JSON.parse(localStorage.getItem(`MAPPING_API_RES`));
      // grouping the predicted column names from the API response for the selected sheet by the column names
      for (let i = 0; i < sheetData[sheet][0]?.length; i++) {
        let targetArr = predictResponse?.filter((res) => res.source_label === sheetData[sheet][0][i])
        const prepareSheetNames = JSON.parse(localStorage.getItem('prepare-sheetNames'));
        // Removing the sheet names from the column names in the predicted label
        if (prepareSheetNames) {
          updatedTargetArr = targetArr?.map((obj) => {
            // Use regex to replace the sheet names in predicted_label
            obj.predicted_label = obj.predicted_label.replace(new RegExp(`^(${prepareSheetNames.join('|')})\\.`), '');
            return obj;
          });
        } else {
          updatedTargetArr = targetArr?.map((obj) => {
            // Split the predicted label by dot
            const labelParts = obj.predicted_label.split('.');
            // Get the last part which is the column name
            const columnName = labelParts[labelParts.length - 1];
            // Return the updated object with only column name
            return {
              ...obj,
              predicted_label: columnName
            };
          });
        }
        const targetArrSorted = updatedTargetArr?.sort((a, b) => b?.prob_score - a?.prob_score);
        source_target_mapped_arr.push({
          id: uuid(),
          source_field: sheetData[sheet][0][i],
          target_field: targetArrSorted ? targetArrSorted[0] : {},
          mapped: targetArrSorted?.length < 1 ? false : true,
          target_field_arr: targetArrSorted,
          mappingRecommendations: false
        })
      }
      setSourceTargetMappedArr(source_target_mapped_arr);
    }
    // eslint-disable-next-line
  }, [sheet])

  const handleBackFromMappingReview = () => {
    setReview(false);
    setDataValidation(true);
    setAutoMappingStepper(false);
    setReviewStepper(false);
    setDataValidationStepper(false);
    setSkipDataValidation(false);
    setDeleteModalOpen(false);
    const response = JSON.parse(localStorage.getItem('MAPPING_API_RES'));
    if (response) {
      localStorage.removeItem('MAPPING_API_RES');
    } else {
      setDataValidation(false);
    }
  }

  const handleOnDrag = (e, id, obj) => {
    e.dataTransfer.setData("object", JSON.stringify(obj));
    e.dataTransfer.setData("id", id);
    setSourceTargetMappedArr(sourceTargetMappedArr => sourceTargetMappedArr.map(obj => id === obj.id ? { ...obj, mapped: false } : obj))
  }

  // const handleOnDrop = (e) => {
  //   const targetField = e.dataTransfer.getData("object");
  //   setUnmappedTargetFields([...unmappedTargetFields, JSON.parse(targetField)])
  // }

  const handleDragOver = (e) => {
    e.preventDefault();
  }

  // const handleOnDragUnmapped = (e, id, obj) => {
  //   e.dataTransfer.setData("id", id);
  //   e.dataTransfer.setData("object", JSON.stringify(obj));
  // }

  const handleOnDropTarget = (e, id) => {
    const obj = e.dataTransfer.getData("object");
    const object = JSON.parse(obj);
    setUnmappedTargetFields((current) => current.filter((obj) => obj.id !== object.id));
    setSourceTargetMappedArr(sourceTargetMappedArr => sourceTargetMappedArr.map(obj => id === obj.id ? { ...obj, mapped: true, target_field: { ...obj.target_field, predicted_label: object.target_field.predicted_label } } : obj))
  }

  const updateTargetField = (e, id) => {
    setSourceTargetMappedArr(sourceTargetMappedArr => sourceTargetMappedArr.map(obj => id === obj.id ? { ...obj, target_field: { ...obj.target_field, predicted_label: e.target.value } } : obj))
  }

  const handleRemoveTargetField = (id, obj) => {
    setSourceTargetMappedArr(sourceTargetMappedArr => sourceTargetMappedArr.map(obj => id === obj.id ? { ...obj, mapped: false } : obj))
    setUnmappedTargetFields((unmappedTargetFields) => [...unmappedTargetFields, obj]);
  }

  //upload csv files to Azure Storage 
  const uploadCsvFile = async (myCsvFile) => {
    const blobServiceClient = new BlobServiceClient(`${localStorage.getItem('sas_url')}`);
    const containerName = user?.current_workspace._id.toString().toLowerCase();
    const containerClient = blobServiceClient.getContainerClient(containerName);
    // Upload the file
    const options = {
      blobHTTPHeaders: {
        blobContentType: "text/csv"
      }
    };

    try {
      // Generate a unique filename using UUID
      const fileName = uuid() + ".csv";
      const blockBlobClient = containerClient.getBlockBlobClient(fileName);
      await blockBlobClient.uploadData(myCsvFile, options);
    } catch (error) {
      console.error('Error uploading File:', error);
    }
  };

  const handleTransferData = async () => {
    setMappingScreen(false);
    setAirbyteLoading(true);
    let finalMappedData = [];
    try {
      const destinationFields = [];
      sourceTargetMappedArr.forEach((object) => {
        if (object.mapped) {
          destinationFields.push(object.target_field.predicted_label);
        }
        else {
          destinationFields.push('');
        }
      })
      finalMappedData = [destinationFields, ...sheetData[sheet].slice(1)];

      const config = {
        headers: {
          "Content-Type": "application/json",
          authorization: `Bearer ${localStorage.getItem("auth_token")}`
        }
      };
      const workspacesResponse = await axios.get(`${API}/admin/workspace/get-workspaces`, config);
      if (!workspacesResponse.data.success) {
        setAirbyteLoading(false);
        setMappingScreen(true);
        toast.error(workspacesResponse.data.error.msg);
        return;
      }
      const workspacesArr = workspacesResponse.data.result;

      const workspaceToUpdate = workspacesArr.find(obj => obj._id === user?.current_workspace._id);

      if (workspaceToUpdate.destination_details.destination_type === "file") {
        const userUpdated = { ...user, current_workspace: workspaceToUpdate };
        updateUser(userUpdated);
        if (finalMappedData) {
          const csv = Papa.unparse(finalMappedData);
          const csvBlob = new Blob([csv], { type: "text/csv;charset=utf-8;" });
          const csvFile = new File([csvBlob], 'data.csv', { type: 'text/csv' });
          await uploadCsvFile(csvFile);
        }

        const transferData = await axios.get(`${API}/airbyte/transfer-data/file-to-blob`, config);
        if (!transferData.data.success) {
          setAirbyteLoading(false);
          setMappingScreen(true);
          toast.error(workspacesResponse.data.error.msg);
          return;
        }
      }

      if (workspaceToUpdate.destination_details.destination_type === "MySQL" || workspaceToUpdate.destination_details.destination_type === "MongoDB") {
        if (finalMappedData) {
          const csv = Papa.unparse(finalMappedData);
          const csvBlob = new Blob([csv], { type: "text/csv;charset=utf-8;" });
          const csvFile = new File([csvBlob], 'data.csv', { type: 'text/csv' });
          await uploadCsvFile(csvFile);
          const transferData = await axios.get(`${API}/airbyte/transfer-data/file-to-db`, config);
          if (!transferData.data.success) {
            setAirbyteLoading(false);
            setMappingScreen(true);
            toast.error(transferData.data.error.msg);
            return;
          }
        }
      }
      setAirbyteLoading(false);
      setSuccess(true);
      setDestinationStepper(true);
    } catch (error) {
      console.error('Error in API calls:', error);
      toast.error("Error in API calls");
      setAirbyteLoading(false);
      setMappingScreen(true);
    }
  }

  console.log(unmappedTargetFields);

  return (
    <>
      {mappingScreen ?
        <div>
          <div className="mt-2 flex justify-between mb-5">
            <div className='flex gap-5 justify-center items-center'>
              <form className="max-w-sm">
                {/* <div className="relative">
              <img src={search} alt="search" className='absolute top-0 bottom-0 my-auto text-gray-400 left-3 ' />
              <input
                type="text"
                placeholder="Search source fields"
                className="w-full py-[0.4rem] pl-12 pr-4 text-gray-500 border rounded-md outline-none text-sm"
              />
            </div> */}
              </form>
              {/* <div className="text-[14px] group">
                <button
                  className="px-3 py-1 rounded-md border-[1px] flex items-center justify-between"
                  onClick={() => setMappingDropdownVisible(!mappingDropdownVisible)}
                >
                  All source fields <img src={downArrow} alt="downarrow" className='ms-3' />
                </button>
                {mappingDropdownVisible && (
                  <div className="relative top-2 text-sm text-[#212529] group-hover:flex">
                    <div className="bg-white absolute w-56 h-fit border-[1px] border-[#707070] rounded-md z-20 py-1">
                      <ul onClick={() =>
                        setMappingDropdownVisible(false)
                      }>
                        <li className="drop-item w-[16.5] py-2 px-3 hover:bg-[#F1F1F1] rounded-r-sm" >
                          Show mapped fields
                        </li>
                        <li className="drop-item w-[16.5] py-2 px-3 hover:bg-[#F1F1F1] rounded-r-sm" >
                          Show unmapped fields
                        </li>
                        <li className="drop-item w-[16.5] py-2 px-3 hover:bg-[#F1F1F1] rounded-r-sm" >
                          Reset mapping
                        </li>
                         <li className="drop-item w-[16.5] py-2 px-3 hover:bg-[#F1F1F1] rounded-r-sm" >
                          Clear mapping
                        </li>
                        <li className="drop-item w-[16.5] py-2 px-3 hover:bg-[#F1F1F1] rounded-r-sm" >
                          All source fields
                        </li>
                      </ul>
                    </div>
                  </div>
                )}
              </div> */}
              {/* <div className='flex gap-2 text-sm font-semibold'>
            <img src={Warn} alt='warn' width="24px" />
            Errors: 2
          </div> */}
            </div>
            <div className='flex gap-2.5 justify-center items-center'>
              <button className="flex items-center gap-2 bg-[#BFBFBF] px-2 py-1 hover:bg-[#A5A5A5] text-white rounded-md text-sm"
                onClick={() => {
                  clearStates();
                }}>
                Cancel
              </button>
              <button className="flex items-center gap-2 bg-[#BFBFBF] px-[10px] py-[5px] hover:bg-[#A5A5A5] back-btn text-white rounded-md text-sm"
                onClick={() => {
                  handleBackFromMappingReview();
                }}>
                {/* <img src={backWhite} alt="back" className='back-btn-img' />  */}
                Back
              </button>
              <button className="flex items-center gap-2 bg-[#0078C5] px-2 py-1 hover:bg-[#00578F] text-white rounded-md text-sm"
                onClick={() => {
                  handleTransferData();
                }}>
                {/* <PiArrowRightBold color="#fff" size={15} />{" "} */}
                Import Data
              </button>
            </div>
          </div>
          <>
            <div className=" mt-2 text-sm flex justify-start">
              {selectedValues?.length === 1 ? (
                <h5 className="mt-0 overflow-auto bg-[#F9FAFB] border-x-[1px] border-t-[1px] border-[#DDDEE0] px-4 py-3 w-full rounded-t-lg font-semibold text-sm">
                  Sheet Name : {sheet}
                </h5>
              ) : (
                <div className="flex">
                  {displayedElements.map((s, i) => (
                    <div className='group relative'>
                      <label htmlFor={`${s}`}>
                        <h5
                          key={i}
                          className={`px-2 py-2.5 text-center rounded-t-lg w-28 border-x-[1px] border-t-[1px] border-[#DDDEE0] drop-item cursor-pointer font-medium ${s === sheet
                            ? "sheet_active"
                            : "bg-[#F9FAFB]"
                            }`}
                        >
                          <input
                            id={`${s}`}
                            name={`${s}`}
                            type="checkbox"
                            className="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 rounded hidden"
                            checked={s === sheet}
                            value={s}
                            onChange={(e) => {
                              setSheet(e.target.value);
                            }}
                          />
                          {s}
                        </h5>
                      </label>
                      <span className='group-hover:visible invisible down-arrow text-sm'>
                        {s}
                      </span>
                    </div>
                  ))}

                </div>
              )}

              {checkboxes.length <= 5 ? (
                " "
              ) : (
                <div className="flex justify-end w-full gap-5 items-center">
                  <div className="flex gap-6">
                    <button
                      onClick={handleBackwardClick}
                      disabled={currentIndex === 0}
                    >
                      {currentIndex === 0 ? (
                        <img src={disableLeft} alt="left" />
                      ) : (
                        <img src={LeftArrow} alt="left" />
                      )}
                    </button>

                    <button
                      onClick={handleForwardClick}
                      disabled={
                        currentIndex ===
                        selectedValues.length - 5
                      }
                    >
                      {currentIndex ===
                        selectedValues.length - 5 ? (
                        <img src={disableRight} alt="right" />
                      ) : (
                        <img src={RightArrow} alt="right" />
                      )}
                    </button>
                  </div>

                  {/* dropdown */}
                  <div className="text-[14px] group">
                    <button
                      className="px-1.5 py-1 rounded-sm border-[1px] flex items-center w-24 justify-between"
                      onClick={() => setDropdownVisible(!dropdownVisible)}
                    >
                      Sheet <img src={downArrow} alt="downarrow" />
                    </button>
                    {dropdownVisible && (
                      <div className="relative right-32 top-2 text-sm text-[#212529] group-hover:flex">
                        <div className="bg-white absolute w-56 h-fit border-[1px] border-[#707070] rounded-md z-20 py-1">
                          {sheetNames.map((n, i) => (
                            <ul key={i}>
                              <li
                                className="drop-item w-[16.5] py-2 px-3 hover:bg-[#F1F1F1] rounded-r-sm"
                                onClick={() =>
                                  setDropdownVisible(false)
                                }
                              >
                                {n}
                              </li>
                            </ul>
                          ))}
                        </div>
                      </div>
                    )}
                  </div>
                </div>
              )}
            </div>
          </>
          <div className='mb-[25px]'>
            <div className='flex flex-col py-5 border border-[#707070] px-[100px] rounded-b-[10px] rounded-tr-[10px] overflow-y-auto'>
              <div className='w-full flex items-center font-semibold mb-4'>
                <div className='w-[53%]'>SOURCE FIELDS</div>
                <div>DESTINATION FIELDS</div>
              </div>

              <div className='w-full flex flex-col gap-3 text-sm text-[#212529] font-semibold max-h-[60vh] overflow-y-auto'>
                {sourceTargetMappedArr.map((obj, index) => (
                  <div className='flex justify-center items-center me-3' key={index}>
                    <div className='w-[48%] h-[43px] rounded-[10px] shadow-[0_0_13px_2px_#efeded] py-[10px] border border-[#00000029] px-[20px] flex items-center'>{obj.source_field}</div>
                    <div className='ms-2 me-3 flex items-center justify-center'>
                      <div className='inline-block w-[3px] h-[3px] rounded-full bg-[#707070]'></div>
                      <div className='inline-block w-[3px] h-[3px] rounded-full bg-[#707070] ms-1'></div>
                      <div className='inline-block w-[3px] h-[3px] rounded-full bg-[#707070] ms-1'></div>
                      <div className='inline-block w-[3px] h-[3px] rounded-full bg-[#707070] ms-1'></div>
                      <div className='inline-block w-[3px] h-[3px] rounded-full bg-[#707070] ms-1'></div>
                      <div className='inline-block w-[3px] h-[3px] rounded-full bg-[#707070] ms-1'></div>
                      <div className='inline-block w-[3px] h-[3px] rounded-full bg-[#707070] ms-1'></div>
                      <div className='inline-block w-[3px] h-[3px] rounded-full bg-[#707070] ms-1'></div>
                    </div>
                    {obj.mapped ?
                      <div className={`w-[48%] h-[43px] rounded-[10px] flex justify-between items-center py-[10px] ${obj?.target_field?.prob_score >= 0.8 ? 'border border-[#00FF08] shadow-[0_0_13px_2px_#00FF081A]' : ''} ${obj?.target_field?.prob_score < 0.8 ? 'border border-[#FFA200] shadow-[0_0_13px_2px_#FFA2001A]' : ''} px-[20px]`} draggable onDragStart={(e) => handleOnDrag(e, obj.id, obj)} onDragOver={handleDragOver} >
                        <div>{obj?.target_field?.predicted_label}</div>
                        <div className='flex gap-3 items-center justify-center'>
                          <MappingPopover obj={obj} index={index} updateTargetField={updateTargetField} setSourceTargetMappedArr={setSourceTargetMappedArr} />
                          <img src={remove} alt="remove" onClick={() => handleRemoveTargetField(obj.id, obj)} />
                        </div>
                      </div> :
                      <div className='w-[48%] rounded-[10px] h-[43px] shadow-[0_0_13px_2px_#FF09001A] py-[10px] border border-[#FF0900] px-[20px]' onDrop={(e) => handleOnDropTarget(e, obj.id)} onDragOver={handleDragOver} ></div>}
                  </div>
                ))}
              </div>

              {/* <div className='font-semibold mt-[30px]'>
                UNMAPPED TARGET FIELDS
              </div>
              <div className='border h-[200px] border-[#83646429] shadow-[0_0_13px_2px_#efeded] mt-4 rounded-[10px] p-5 font-semibold text-[#212529] text-[14px] mb-[50px]' onDrop={handleOnDrop} onDragOver={handleDragOver}>
                <div className='flex items-center gap-5 flex-wrap'>
                  {unmappedTargetFields.map((obj, i) => (
                    <div className='h-[43px] border border-[#616161] rounded-[10px] flex items-center px-4 justify-center hover:bg-[#0083BF] hover:text-white hover:font-medium' key={i} draggable
                      onDragStart={(e) => handleOnDragUnmapped(e, obj.id, obj)} >
                      {obj.target_field.predicted_label}
                    </div>
                  ))}
                </div>
              </div> */}
            </div>
          </div>
        </div> : ''
      }

      {airbyteLoading ?
        <div className="flex justify-center items-center mt-28 flex-col">
          <div className="flex flex-col justify-center items-center">
            <img src={loadingGif} alt="loading" className="w-[50px] h-[65px]" />
            <h5 className="mt-5">Connecting to ETL Service....</h5>
          </div>
        </div> : ''
      }

      {success ?
        <Success clearStates={clearStates} /> : ''
      }
    </>
  )
}

export default MappingTable


const MappingPopover = ({ obj, index, setSourceTargetMappedArr, updateTargetField }) => {
  const [mappingRecommendationsOpen, setMappingRecommendationsOpen] = useState(false);

  const handleMappingRecommendations = (id) => {
    setMappingRecommendationsOpen(!mappingRecommendationsOpen);
    setSourceTargetMappedArr(sourceTargetMappedArr => sourceTargetMappedArr.map(obj => id === obj.id ? { ...obj, mappingRecommendations: true } : { ...obj, mappingRecommendations: false }))
  }

  return (
    <>
      {obj.target_field?.prob_score >= 0.8 ?
        <img src={bulb} alt="bulb" className='mb-1 cursor-default' />
        :
        <div className='relative'>
          <BulbIcon mappingRecommendations={obj.target_field_arr?.length} mappingRecommendationsOpen={mappingRecommendationsOpen} setMappingRecommendationsOpen={setMappingRecommendationsOpen} handleMappingRecommendations={handleMappingRecommendations} obj={obj} />
          {mappingRecommendationsOpen && obj.mappingRecommendations ?
            <div className='absolute bg-[#fff] -top-[7px] w-64 cursor-pointer h-64 z-10 py-[15px] px-[15px] right-10 border border-[#616161] rounded-[5px] overflow-y-auto'>
              <div className='text-sm font-semibold'>Mapping recommendations</div>
              <hr className='border border-[#DDDEE0] my-[10px]' />
              <div className='text-[12px] font-semibold mb-1'>SOURCE FIELD</div>
              <div className='text-[12px] text-[#212529] mb-2'>{obj.source_field}</div>
              {
                obj.target_field_arr.map((item, i) => (
                  <div className='mb-1'>
                    <label htmlFor={i} className='text-[#212529] font-normal text-[12px]'><input type="radio" name={`map-recommendations${index}`} id={i} value={item.predicted_label} onChange={(e) => updateTargetField(e, obj.id)} className='me-2' />{item.predicted_label}</label>
                  </div>
                ))
              }
            </div> : ''
          }
        </div>
      }
    </>
  )
}

const BulbIcon = ({ mappingRecommendations, mappingRecommendationsOpen, setMappingRecommendationsOpen, handleMappingRecommendations, obj }) => {
  return (
    <div className='cursor-pointer' onClick={() => handleMappingRecommendations(obj.id)}>
      <svg id="Component_34_2" data-name="Component 34 – 2" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" width="31" height="25" viewBox="0 0 31 25">
        <defs>
          <clipPath id="clip-path">
            <rect id="Rectangle_132" data-name="Rectangle 132" width="25" height="25" fill="#707070" />
          </clipPath>
        </defs>
        <g id="Component_13_3" data-name="Component 13 – 3">
          <g id="Group_62" data-name="Group 62" clipPath="url(#clip-path)">
            <path id="Path_31" data-name="Path 31" d="M0,183.928a.6.6,0,0,1,.639-.38c.633.007,1.267,0,1.9,0,.352,0,.552.169.582.462a.48.48,0,0,1-.46.572c-.73.02-1.461.012-2.192,0A.519.519,0,0,1,0,184.221Z" transform="translate(0 -171.579)" fill="#707070" />
            <path id="Path_32" data-name="Path 32" d="M339.043,184.246a.6.6,0,0,1-.64.38c-.625-.007-1.251,0-1.876,0a.562.562,0,0,1-.53-.235.511.511,0,0,1,.385-.8c.73-.027,1.461-.013,2.192-.006a.521.521,0,0,1,.469.368Z" transform="translate(-314.043 -171.605)" fill="#707070" />
            <path id="Path_33" data-name="Path 33" d="M184.6,0a.6.6,0,0,1,.38.639c-.007.625,0,1.25,0,1.876a.569.569,0,0,1-.214.516.513.513,0,0,1-.819-.371c-.027-.73-.013-1.461-.006-2.191A.518.518,0,0,1,184.31,0Z" transform="translate(-171.957)" fill="#707070" />
            <path id="Path_34" data-name="Path 34" d="M87.481,99.812c-.439,0-.88-.025-1.318,0a1.677,1.677,0,0,1-1.8-1.749c-.021-.674-.027-1.351,0-2.025a3.633,3.633,0,0,0-1.436-3.058A7.4,7.4,0,0,1,81,90.65a7.277,7.277,0,1,1,13.682-4.385,6.8,6.8,0,0,1-2.5,6.567,3.946,3.946,0,0,0-1.55,2.664c-.054.5-.023,1.007-.027,1.511,0,.407,0,.813,0,1.22a1.6,1.6,0,0,1-1.587,1.584c-.512.005-1.025,0-1.538,0m2.076-4.176a.865.865,0,0,0,.023-.1,4.931,4.931,0,0,1,.818-2.268,7.377,7.377,0,0,1,1.335-1.412A6.2,6.2,0,0,0,92.009,83a6.142,6.142,0,0,0-8.47-.454,6.055,6.055,0,0,0-2.207,5.8,5.783,5.783,0,0,0,2.179,3.77,4.685,4.685,0,0,1,1.672,2.315c.118.384.172.788.259,1.2ZM85.395,96.7c0,.428,0,.833,0,1.237a2.2,2.2,0,0,0,.032.339.508.508,0,0,0,.576.5c.293,0,.585,0,.878,0,.7,0,1.4,0,2.1,0,.35,0,.557-.141.571-.437.025-.54.007-1.083.007-1.636Z" transform="translate(-74.982 -74.821)" fill="#707070" />
            <path id="Path_35" data-name="Path 35" d="M54.223,53.523a1.238,1.238,0,0,1,.311.179q.726.7,1.429,1.426a.509.509,0,0,1-.061.805.489.489,0,0,1-.745-.063q-.69-.737-1.377-1.478a.48.48,0,0,1-.094-.562.543.543,0,0,1,.536-.307" transform="translate(-50.143 -50.033)" fill="#707070" />
            <path id="Path_36" data-name="Path 36" d="M292.37,293.909a2.538,2.538,0,0,1-.5-.292c-.444-.418-.87-.855-1.3-1.29a.5.5,0,0,1-.046-.756.48.48,0,0,1,.7-.032c.515.5,1.019,1.005,1.52,1.517a.469.469,0,0,1,.074.545.339.339,0,0,1-.421.239l-.029.069" transform="translate(-271.471 -272.4)" fill="#707070" />
            <path id="Path_37" data-name="Path 37" d="M293.3,55.715a1.174,1.174,0,0,1-.174.309q-.677.7-1.378,1.382a.536.536,0,0,1-.908-.167.629.629,0,0,1,.125-.483c.469-.507.968-.986,1.459-1.473a.478.478,0,0,1,.562-.1.534.534,0,0,1,.313.537" transform="translate(-271.9 -51.536)" fill="#707070" />
            <path id="Path_38" data-name="Path 38" d="M54.215,293.916c-.24-.036-.429-.1-.512-.32a.514.514,0,0,1,.142-.577c.472-.469.939-.943,1.414-1.408a.513.513,0,1,1,.723.725c-.46.469-.923.936-1.4,1.391a1.447,1.447,0,0,1-.37.19" transform="translate(-50.173 -272.427)" fill="#707070" />
            <path id="Path_39" data-name="Path 39" d="M184.569,119.791a4.677,4.677,0,0,1,4.531,4.61c0,.441-.3.686-.691.565a.492.492,0,0,1-.347-.525,3.587,3.587,0,0,0-.889-2.318,3.655,3.655,0,0,0-2.734-1.3.515.515,0,0,1-.481-.778c.1-.2.262-.262.612-.256" transform="translate(-171.914 -111.979)" fill="#707070" />
          </g>
        </g>
        <g id="Group_94" data-name="Group 94" transform="translate(-1140 -393)">
          <circle id="Ellipse_62" data-name="Ellipse 62" cx="7" cy="7" r="7" transform="translate(1157 395)" fill="#0083bf" />
          <text id="_4" data-name="4" transform="translate(1161 405)" fill="#fff" fontSize="8" fontFamily="Poppins-Medium, Poppins" fontWeight="500"><tspan x="0" y="0">{mappingRecommendations}</tspan></text>
        </g>
      </svg>
    </div>

  )
}

