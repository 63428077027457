import React, { useState } from "react";
import { PiArrowRightBold } from "react-icons/pi";
import { IoEyeOutline } from "react-icons/io5";
import Pagination from "./Pagination/Pagination";

const SheetLists = ({ checkboxes, handleSubmit, setSheet, sheetNames, 
  handleCheckboxChange, handleSingleSheet, handleSelectAll, checkAll, excel }) => {
  const [currentPage, setCurrentPage] = useState(1);
  const [itemsPerPage, setItemsPerPage] = useState(10);
  const indexOfLastItem = currentPage * itemsPerPage;
  const indexOfFirstItem = indexOfLastItem - itemsPerPage;

  const currentItems = sheetNames.slice(indexOfFirstItem, indexOfLastItem);

  return (
    <div className="pb-8">
      <div className="ps-[25px] border-l-[1px] border-[#CCCCCC]">
        <div className="flex justify-between items-center">
          <span>Preview</span>
          <span>
            {checkboxes.length === 0 ? (
              <button
                className="flex items-center gap-2 bg-[#9e9e9e] px-2 py-1 hover:bg-[#949494] text-white rounded-md text-sm cursor-not-allowed"
              >
                <PiArrowRightBold color="#fff" size={15} /> Continue
              </button>
            ) : (
              <button
                className="flex items-center gap-2 bg-[#0078C5] px-2 py-1 hover:bg-[#00578F] text-white rounded-md text-sm cursor-pointer"
                onClick={() => {
                  handleSubmit();
                  handleSingleSheet();
                }}
              >
                <PiArrowRightBold color="#fff" size={15} /> Continue
              </button>
            )}
          </span>
        </div>
        <div className="mt-5 w-full border-[1px] border-[#DDDEE0] rounded-lg text-sm">
          <div className="flex gap-7 border-b border-[#DDDEE0] p-3 bg-[#F9FAFB] rounded-t-lg">
            <div className="flex items-center">
              <input
                id="checkbox-all-search"
                type="checkbox"
                className="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 rounded cursor-pointer"
                onChange={(e) => handleSelectAll(e.target.checked)}
              />
              <label htmlFor="checkbox-all-search" className="ps-7 cursor-pointer">
                <h2 className="text-[#212529] text-sm font-semibold py-1">
                  {excel ? "Sheet Names" : "Table Names"}
                </h2>
              </label>
            </div>
          </div>
          <div>
            {currentItems.map((s, i) => (
              <div
                className="flex gap-7 border-b border-[#DDDEE0] p-3 group justify-between"
                key={i}
              >
                <li className="py-1 text-sm list-none last:border-none">
                  <div className="flex items-center gap-7">
                    <input
                      id={`${s}`}
                      name={`${s}`}
                      type="checkbox"
                      className="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 rounded cursor-pointer"
                      checked={checkAll || null}
                      onChange={(e) => {
                        setSheet(e.target.value);
                        handleCheckboxChange(e.target.value, e.target.checked);
                      }}
                      value={s}
                    />
                    <label htmlFor={`${s}`} className=" text-black cursor-pointer">
                      {s}
                    </label>
                  </div>
                </li>
                {checkAll ? (
                  ""
                ) : (
                  <>
                    {!checkboxes.includes(s) ? (
                      <button className="hidden group-hover:flex">
                        <span className="flex gap-2 items-center bg-[#747575] text-white text-sm px-2 py-1 rounded">
                          <IoEyeOutline color="#fff" fontSize="17px" />
                          Preview
                        </span>
                      </button>
                    ) : (
                      <button className="hidden group-hover:flex">
                        <span
                          className="flex gap-2 items-center bg-[#0083BF] text-white text-sm px-2 py-1 rounded"
                          onClick={() => {
                            handleSubmit();
                            handleSingleSheet();
                          }}
                        >
                          <IoEyeOutline color="#fff" fontSize="17px" />
                          Preview
                        </span>
                      </button>
                    )}
                  </>
                )}
              </div>
            ))}
          </div>
        </div>
      </div>
      <Pagination data={sheetNames} currentPage={currentPage} setCurrentPage={setCurrentPage} itemsPerPage={itemsPerPage} setItemsPerPage={setItemsPerPage} indexOfFirstItem={indexOfFirstItem} indexOfLastItem={indexOfLastItem} />
    </div>
  );
};

export default SheetLists;
