import React, { useState } from 'react';
import nextBtn from "../../../assets/nextBtn.svg";
import previousBtn from "../../../assets/previousBtn.svg";

const Pagination = ({ data, currentPage, setCurrentPage, itemsPerPage, setItemsPerPage,
  indexOfFirstItem, indexOfLastItem }) => {
  const pageNumberLimit = 5;
  const [maxPageNumberLimit, setmaxPageNumberLimit] = useState(10);
  const [minPageNumberLimit, setminPageNumberLimit] = useState(0);

  const pages = [];
  for (let i = 1; i <= Math.ceil(data.length / itemsPerPage); i++) {
    pages.push(i);
  }

  const handleClick = (event) => {
    setCurrentPage(Number(event.target.id));
  };

  const renderPageNumbers = pages.map((number) => {
    if (number < maxPageNumberLimit + 1 && number > minPageNumberLimit) {
      return (
        <li
          key={number}
          id={number}
          onClick={handleClick}
          className={`${currentPage === number ? "bg-[#0083BF] text-white" : ""} border-lite-grey-4 border h-10 px-4 flex items-center cursor-pointer`}
        >
          {number}
        </li>
      );
    } else {
      return null;
    }
  });

  const handleNextBtn = () => {
    setCurrentPage(currentPage + 1);
    if (currentPage + 1 > maxPageNumberLimit) {
      setmaxPageNumberLimit(maxPageNumberLimit + pageNumberLimit);
      setminPageNumberLimit(minPageNumberLimit + pageNumberLimit);
    }
  };

  const handlePrevBtn = () => {
    setCurrentPage(currentPage - 1);
    if ((currentPage - 1) % pageNumberLimit === 0) {
      setmaxPageNumberLimit(maxPageNumberLimit - pageNumberLimit);
      setminPageNumberLimit(minPageNumberLimit - pageNumberLimit);
    }
  };

  let pageIncrementBtn = null;
  if (pages.length > maxPageNumberLimit) {
    pageIncrementBtn = <li className='border-lite-grey-4 border h-10 px-4 flex items-center pb-2 cursor-pointer' onClick={handleNextBtn}>&hellip;</li>
  }

  let pageDecrementBtn = null;
  if (minPageNumberLimit >= 1) {
    pageDecrementBtn = <li className='border-lite-grey-4 border h-10 px-4 flex items-center pb-2 cursor-pointer' onClick={handlePrevBtn}>&hellip;</li>
  }

  return (
    <div className='flex justify-between mt-8 text-sm text-lite-black'>
      <div className='ms-8'> <label htmlFor="rows">Rows per page:</label>
        <select id="rows" className='outline-none ms-2'
          value={itemsPerPage}
          onChange={(e) => setItemsPerPage(e.target.value)}
        >
          <option selected value="10">10</option>
          <option value="20">20</option>
          <option value="30">30</option>
        </select>
      </div>
      <div>Showing {indexOfFirstItem + 1} to {indexOfLastItem} of {data.length} results </div>
      <ul className='list-none flex'>
        <button onClick={handlePrevBtn} className='border-lite-grey-4 border h-10 px-4 flex items-center rounded-tl-md rounded-bl-md hover:bg-blue-1' disabled={(currentPage === pages[0] || pages.length === 0) ? true : false}>
          <img src={previousBtn} alt="previous" />
        </button>
        {pageDecrementBtn}
        {renderPageNumbers}
        {pageIncrementBtn}
        <button onClick={handleNextBtn} className='border-lite-grey-4 border h-10 px-4 flex items-center rounded-tr-md rounded-br-md hover:bg-blue-1' disabled={(currentPage === pages[pages.length - 1] || pages.length === 0) ? true : false}>
          <img src={nextBtn} alt="next" />
        </button>
      </ul>
    </div>
  )
}

export default Pagination
