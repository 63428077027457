import Upload from "../../assets/upload.png";
import Db from "../../assets/Database.png";
import Api from "../../assets/api.png";
import axios from 'axios'
import { useEffect, useState } from "react";
import PrepareStepper from "../ReUseable/Stepper/PrepareStepper";
// import { toast } from "react-toastify";
import toast from 'react-hot-toast';
import "react-toastify/dist/ReactToastify.css";
import Papa from "papaparse";
import * as XLSX from "xlsx";
import ConnectToDb from "./ConnectToDb/ConnectToDb";
import ClickToUploadFile from "./ClickToUploadFile/ClickToUploadFile";
// import { VscChromeClose } from "react-icons/vsc";
import ConnectToApi from "./ConnectToApi/ConnectToApi";
// import Loader from "../../assets/Mask Group 6.svg";
import loadingGif from "../../assets/loading.gif";
import { useAuth } from "../../hooks/useAuth";
import { AI_API, API } from "../../global";
import { BlobServiceClient } from '@azure/storage-blob';
import { v4 as uuid } from 'uuid';
import Success from "../ReUseable/Success/Success";
// import ThankYou from "../ReUseable/ThankYou/ThankYou";

export default function Prepare() {
  const [fileuploaded, setFileUploaded] = useState(false);
  const [docum, setDocum] = useState("");
  const [sourceType, setSourceType] = useState(true);
  const [fileType, setFileType] = useState("");
  const [fileName, setFileName] = useState("");
  const [loading, setLoading] = useState(false);
  const [uloading, setULoading] = useState(false);
  const [sheet, setSheet] = useState(null);
  const [sheetData, setSheetData] = useState(null);
  const [SheetNames, setSheetNames] = useState(null);
  const [SheetCount, setSheetCount] = useState(0);
  const [sname, setSname] = useState(false);
  const [csvTable, setCsvTable] = useState(false);
  const [values, setValues] = useState([]);
  const [tableRows, setTableRows] = useState([]);
  const [csvdata, setCsvData] = useState([]);
  const [tableshow, setTableshow] = useState(false);
  const [selectedValues, setSelectedValues] = useState([]);

  const [checkboxes, setCheckboxes] = useState([]);
  const { user, updateUser } = useAuth();

  const [thankyou, setThankyou] = useState(false);

  const [singleSheet, setSingleSheet] = useState(false);

  const [connectDb, setConnectDb] = useState(false);
  const [connectApi, setConnectApi] = useState(false);
  const [DborApi, setDborApi] = useState(false);
  const [checkAll, setCheckAll] = useState(false);

  const [previewStepper, setPreviewStepper] = useState(false);

  const acceptableFileName = ["xlsx", "xls", "csv"];
  const [messageIndex, setMessageIndex] = useState(0);
  const messages = ["Uploading Data...", "Analysing...", "Training Model..."];

  useEffect(() => {
    let timeout;
    if (uloading && messageIndex < messages.length - 1) {
      timeout = setTimeout(() => {
        setMessageIndex((prevIndex) => prevIndex + 1);
      }, 2000); // Change message every 2 seconds
    }
    return () => clearTimeout(timeout);
    // eslint-disable-next-line
  }, [uloading, messageIndex]);

  const handleFile = async (e) => {
    setPreviewStepper(true);
    setDborApi(false);
    setConnectApi(false);
    setConnectDb(false);
    setSourceType(false);
    setLoading(true);
    setMessageIndex(0);
    // setUploadCsv(e.target.files[0]);
    setFileUploaded(true);
    setDocum(e.target.files[0]);
    const myFile = e.target.files[0];
    if (!myFile) return;
    const name = myFile.name;
    setFileName(name);

    const ft = name.split(".").pop().toLowerCase();
    setFileType(ft);

    if (!acceptableFileName.includes(ft)) {
      toast.warning("Invalid File Type");
      return;
    }

    if (ft === "csv") {
      Papa.parse(myFile, {
        header: true,
        skipEmptyLines: true,
        complete: function (results) {
          console.log(results);
          setCsvData(results.data);
          console.log(results.data);
          setSname(false);
          const rowsArray = [];
          const valuesArray = [];

          // Iterating data to get column name and their values
          results.data.map((d) => rowsArray.push(Object.keys(d)));

          results.data.map((d) => valuesArray.push(Object.values(d)));

          // Filtered Column Names
          setTableRows(rowsArray[0]);

          // Filtered Values
          setValues(valuesArray);
          setLoading(false);
        },
      });
      setCsvTable(true);
    }

    if (ft === "xls" || ft === "xlsx") {
      // Read the XLSX Metadata
      const data = await myFile.arrayBuffer();
      const wb = XLSX.read(data);
      localStorage.setItem('prepare-sheetNames', JSON.stringify(wb.SheetNames));
      setSheetNames(wb.SheetNames);
      var mySheetData = {};
      // Loop through the sheets
      for (var i = 0; i < wb.SheetNames.length; i++) {
        setLoading(true);
        let sheetName = wb.SheetNames[i];
        const worksheet = wb.Sheets[sheetName];
        const jsonData = XLSX.utils.sheet_to_json(worksheet, {
          blankrows: "",
          header: 1,
          defval: "",
          cellDates: true,// Enable date parsing
          // dateNF: 'yyyy-mm-dd',
          raw: false
          // Ensure dates are returned as Date objects
        });
        mySheetData[sheetName] = jsonData;
      }
      setSname(true);
      setSheet(Object.keys(mySheetData)[0]);
      setSheetData(mySheetData);
      const sheetCount = Object.keys(mySheetData).length;
      setSheetCount(sheetCount);
      setLoading(false);
    }
  };

  const handleShowSingleTable = () => {
    setCheckboxes([]);
    setSelectedValues("");
    setSname(false);
    setSingleSheet(false);
    setCheckAll(false);
    setFileUploaded(false);
    setThankyou(true);
  };

  //upload excel file to Azure Storage 
  const uploadExcelFile = async (myFile) => {
    const blobServiceClient = new BlobServiceClient(`${localStorage.getItem('sas_url')}`);
    const containerName = user?.current_workspace._id.toString().toLowerCase();
    const containerClient = blobServiceClient.getContainerClient(containerName);
    // Upload the file
    const options = {
      blobHTTPHeaders: {
        blobContentType: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
      }
    };

    try {
      // Generate a unique filename using UUID
      const fileName = uuid() + ".xlsx";
      const blockBlobClient = containerClient.getBlockBlobClient(fileName);
      await blockBlobClient.uploadData(myFile, options);
      return blockBlobClient.url;
    } catch (error) {
      console.error('Error uploading File:', error);
    }
  };

  //upload csv files to Azure Storage 
  const uploadCsvFile = async (myCsvFile) => {
    const blobServiceClient = new BlobServiceClient(`${localStorage.getItem('sas_url')}`);
    const containerName = user?.current_workspace._id.toString().toLowerCase();
    const containerClient = blobServiceClient.getContainerClient(containerName);

    try {
      const config = {
        headers: {
          "Content-Type": "application/json",
          authorization: `Bearer ${localStorage.getItem("auth_token")}`
        }
      };
      await axios.get(`${API}/airbyte/delete-blobs`, config);
      // Upload the new CSV file
      const options = {
        blobHTTPHeaders: {
          blobContentType: "text/csv"
        }
      };
      // Generate a unique filename using UUID
      const fileName = uuid() + ".csv";
      const blockBlobClient = containerClient.getBlockBlobClient(fileName);
      await blockBlobClient.uploadData(myCsvFile, options);
    } catch (error) {
      console.error('Error uploading File:', error);
    }
  }


  const handlePreview = async () => {
    const myFile = docum;
    if (!myFile) return;
    const name = myFile.name;
    const ft = name.split(".").pop().toLowerCase();
    const file_name = name;
    if (ft === "csv") {
      setULoading(true);
      const csvF = Papa.unparse(csvdata);
      const upload = new Blob([csvF], { type: "text/csv;charset=utf-8;" });
      upload.name = file_name;
      const formData = new FormData();
      formData.append("file", upload, file_name + ".csv");
      formData.append("uuid", user?.current_workspace.workspace_unique_id);
      const res = await axios.post(`${AI_API}/train`, formData, {
        "Content-Type": "multipart/form-data",
      });
      console.log(res);
      setULoading(false);
    }
    else if (ft === "xls" || ft === "xlsx") {
      if (docum) {
        try {
          setULoading(true);
          const fileUrl = await uploadExcelFile(docum);
          const fileDetails = {
            url: fileUrl,
            uuid: user?.current_workspace.workspace_unique_id
          }
          const config = {
            headers: {
              "Content-Type": "application/json",
              authorization: `Bearer ${localStorage.getItem("auth_token")}`
            }
          };
          const res = await axios.post(`${API}/ai-api/train`, fileDetails, config);
          if (res.data.success) {
            // convert the excel file to csv and upload to azure blob storage
            const data = await docum.arrayBuffer();

            // Read Excel file directly from File object
            const wb = XLSX.read(data);

            // Assume there's only one sheet in the Excel file  
            const sheetName = wb.SheetNames[0];
            const worksheet = wb.Sheets[sheetName];

            // Convert to CSV
            const csvData = XLSX.utils.sheet_to_csv(worksheet);

            // Create a Blob object for CSV data
            const csvBlob = new Blob([csvData], { type: 'text/csv' });
            const csvFile = new File([csvBlob], 'data.csv', { type: 'text/csv' });

            await uploadCsvFile(csvFile);

            const updateDestination = {
              destination_type: "file"
            }
            const res = await axios.put(`${API}/admin/workspace/update-workspace/${user?.current_workspace._id}`,
              updateDestination, config);
            if (!res.data.success) {
              setULoading(false);
              setFileUploaded(true);
              toast.error(res.data.error.msg);
              return;
            }

            const workspacesResponse = await axios.get(`${API}/admin/workspace/get-workspaces`, config);
            if (!workspacesResponse.data.success) {
              setULoading(false);
              setFileUploaded(true);
              toast.error(workspacesResponse.data.error.msg);
              return;
            }
            const workspacesArr = workspacesResponse.data.result;

            const workspaceToUpdate = workspacesArr.find(obj => obj._id === user?.current_workspace._id);
            if (workspaceToUpdate.is_train_data_uploaded) {
              const userUpdated = { ...user, current_workspace: workspaceToUpdate };
              updateUser(userUpdated);
            }
            setULoading(false);
            handleShowSingleTable();
          } else {
            setULoading(false);
            setFileUploaded(true);
            toast.error(res.data.error.msg);
          }
        }
        catch (error) {
          toast.error('It appears that there is an error in the backend API. Please try again.');
          console.log(error);
          setULoading(false);
          clearStates();
        }
      }
    }
  };

  const clearStates = () => {
    setSheetCount(0);
    setCheckAll(false);
    setTableshow(false);
    setSourceType(true);
    setFileUploaded(false);
    setPreviewStepper(false);
    setSname(false);
    setCheckAll(false);
    setThankyou(false);
    setMessageIndex(0);
    setSelectedValues([]);
  };

  const handleDb = () => {
    setSourceType(false);
    setConnectDb(true);
    setDborApi(true);
    setFileUploaded(false);
  };

  const handleApi = () => {
    setSourceType(false);
    setConnectApi(true);
    setDborApi(false);
    setConnectDb(false);
    setFileUploaded(false);
  };



  return (
    <div className="mt-16 ms-[50px] px-5 py-1">
      {/* stepper */}
      <PrepareStepper previewStepper={previewStepper} thankyou={thankyou} connectDb={connectDb} connectApi={connectApi} />
      {!sourceType ? (
        <div>
          {fileuploaded ? (
            <ClickToUploadFile
              setSourceType={setSourceType}
              setFileUploaded={setFileUploaded}
              singleSheet={singleSheet}
              setSingleSheet={setSingleSheet}
              setTableshow={setTableshow}
              sname={sname}
              previewStepper={previewStepper}
              setSname={setSname}
              SheetNames={SheetNames}
              fileType={fileType}
              fileName={fileName}
              sheet={sheet}
              setSheet={setSheet}
              SheetCount={SheetCount}
              setSheetCount={setSheetCount}
              loading={loading}
              values={values}
              sheetData={sheetData}
              csvTable={csvTable}
              tableRows={tableRows}
              tableshow={tableshow}
              setThankyou={setThankyou}
              handlePreview={handlePreview}
              setCheckAll={setCheckAll}
              checkAll={checkAll}
              setPreviewStepper={setPreviewStepper}
              setSelectedValues={setSelectedValues}
              selectedValues={selectedValues}
              checkboxes={checkboxes} setCheckboxes={setCheckboxes}
            />
          ) : ""
          }
          <>
            {DborApi ? (
              <>
                {connectDb ? (
                  <ConnectToDb setSourceType={setSourceType} setPreviewStepper={setPreviewStepper} thankyou={thankyou}
                    selectedValues={selectedValues} setSelectedValues={setSelectedValues} tableshow={tableshow} setFileUploaded={setFileUploaded}
                    checkAll={checkAll} setCheckAll={setCheckAll} sname={sname} setSingleSheet={setSingleSheet}
                    handlePreview={handlePreview} previewStepper={previewStepper} setULoading={setULoading}
                    setSname={setSname} setTableshow={setTableshow} sheet={sheet} setSheet={setSheet} sheetData={sheetData}
                    setSheetData={setSheetData} setSheetNames={setSheetNames} SheetNames={SheetNames} clearStates={clearStates}
                    setThankyou={setThankyou} setCheckboxes={setCheckboxes} checkboxes={checkboxes}
                    uploadCsvFile={uploadCsvFile} uploadExcelFile={uploadExcelFile} />
                ) : (
                  ""
                )}
              </>
            ) : (
              <>{connectApi ?
                <ConnectToApi setSourceType={setSourceType} tableshow={tableshow}
                  setSheetData={setSheetData} setSheet={setSheet} setSheetNames={setSheetNames}
                  setPreviewStepper={setPreviewStepper} setSname={setSname} sname={sname}
                  checkboxes={checkboxes} setSelectedValues={setSelectedValues} thankyou={thankyou}
                  setSingleSheet={setSingleSheet} setTableshow={setTableshow} setCheckAll={setCheckAll}
                  setCheckboxes={setCheckboxes} checkAll={checkAll} SheetNames={SheetNames} setThankyou={setThankyou}
                  clearStates={clearStates} sheetData={sheetData} sheet={sheet} selectedValues={selectedValues}
                /> : ""}</>
            )}
          </>
        </div>
      ) : (
        <div>
          <div className="border-b-[1px] border-gray-200 mt-10">
            <button
              className='bg-[#0083BF] text-[#ffffff] text-base px-2.5 py-3.5 font-medium border-none rounded-t-lg'
            >
              Destination Type
            </button>
          </div>

          {/* destination-type */}
          <div className="flex gap-6 mt-5">
            <div className="flex flex-col gap-2 items-center border-dashed border-[1px] border-[#B5B5B5] p-7 w-72 rounded-xl">
              <label
                htmlFor="files"
                className="flex justify-center mb-2 cursor-pointer"
              >
                <img src={Upload} alt="upload" />
              </label>
              <input
                id="files"
                type="file"
                accept=".csv,.xlsx,.xls"
                className="hidden"
                name="source_type"
                onChange={handleFile}
              />
              <div className="font-medium text-lg">
                Select a file
              </div>
              <div className="font-regular text-xs text-center">
                Please upload .xlsx or .csv file. <br />
                The maximum size of file is 1 GB
              </div>
            </div>

            <div
              className='flex flex-col gap-2 items-center border-dashed border-[1px] border-[#B5B5B5] p-7 w-72 rounded-xl'>
              <img src={Db} alt="db" onClick={() => handleDb()} className="cursor-pointer" />
              <div className="font-medium text-lg">Connect to database</div>
              <div className="font-regular text-xs text-center">
                Please connect with database <br /> like SQL, MySQL,
                MongoDB
              </div>
            </div>

            <div
              className='flex flex-col gap-2 items-center border-dashed 
                border-[1px] border-[#B5B5B5] p-7 w-72 rounded-xl'
            >
              <img src={Api} alt="api" onClick={() => handleApi()} className="cursor-pointer" />
              <div className="font-medium text-lg">Connect to an API</div>
              <div className="font-regular text-xs text-center">
                Please connect with an API <br /> using url & token
              </div>
            </div>
          </div>

          <div className="bg-[#D4F1FF] border border-teal rounded-xl p-5 my-10 mx-12 text-lite-black">
            <div className="text-lg font-medium">Preparation</div>
            <div className="text-sm font-normal mt-2.5">
              {user?.current_workspace?.is_trained ?
                <>
                  The workspace is already trained. Your previous training data will be replaced during the training process.
                </>
                : user?.current_workspace?.is_train_data_uploaded ?
                  <>
                    Your train data is uploaded. Training is in progress. we will notify you once completed.
                  </> :
                  <>In order to explore your target store, please provide the details to
                    connect, an exploratory process will kick-in. We will notify you once
                    the exploration is complete.
                  </>
              }
            </div>
          </div>
        </div>
      )}
      {uloading ?
        <div className="pt-10">
          <div className="flex justify-center flex-col mt-10 items-center">
            {/* <img src={Loader} alt="" /> */}
            <img src={loadingGif} alt="uploading" className="w-[50px] h-[65px]" />
            <h5 className="analyse mt-5">{messages[messageIndex]}</h5>
          </div>
        </div> : <>
          {thankyou ? (
            <Success clearStates={clearStates} prepare={true} />
          ) : (
            ""
          )}
        </>}
    </div>
  );
}