import React from 'react';
import closeIcon from "../../../assets/closeIcon.svg";
import greenTickBig from "../../../assets/greenTickBig.svg";

const Success = ({ clearStates, prepare }) => {
  return (
    <div className="flex justify-center mt-16 w-full">
      <div className="bg-[#eff0f3] flex flex-col items-center gap-3 rounded-xl p-5">
        <div className="flex justify-end w-full cursor-pointer" onClick={clearStates}>
          <img src={closeIcon} alt="close" />
        </div>
        <img src={greenTickBig} alt="tick" className='w-14 h-14' />
        <div className="text-3xl font-medium">Success</div>
        <div className="text-xs">
          {prepare ?
            "Data upload is complete , model training initiated"
            :
            "Data transformed and loaded successfully to the destination"
          }
        </div>
      </div>
    </div>
  )
}

export default Success