import React, { useEffect, useRef, useState } from 'react';
import downArrow from "../../../assets/downArrow.svg";

const Dropdown = ({ options, selectedValue, updateSelectedValue, rule, placeholder, validationGroups, 
  getSelectedValidationGroupRules, width, sheet }) => {
  const [dropdownOpen, setDropdownOpen] = useState(false);
  const [selected, setSelected] = useState(selectedValue);
  const dropdownRef = useRef(null);

  const handleClickOutside = (event) => {
    if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
      // Clicked outside the dropdown, so close it
      setDropdownOpen(false);
    }
  };
  
  useEffect(() => {
    // Attach the event listener when the component mounts
    document.addEventListener('mousedown', handleClickOutside);

    // Clean up the event listener when the component unmounts
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
    // eslint-disable-next-line
  }, []);

  const handleSelection = (value) => {
    if (validationGroups) {
      setSelected(value.group_name);
      getSelectedValidationGroupRules(value);
    }
    else {
      setSelected(value);
      updateSelectedValue(value, rule.id);
    }
    setDropdownOpen(false);
  }

  return (
    <div className={`${width} font-medium relative cursor-pointer`} ref={dropdownRef}>
      <div className={`bg-white px-2.5 py-1.5 text-sm flex items-center justify-between rounded-md border border-lite-grey`} onClick={() => setDropdownOpen(!dropdownOpen)}>
        {validationGroups ?
          <>
            {selected ? <span className='text-lite-black overflow-hidden whitespace-nowrap me-3 text-ellipsis'>{selected}</span>
              : <span className='text-lite-grey-3 overflow-hidden whitespace-nowrap me-3 text-ellipsis'>{placeholder}</span>}
          </> :
          <>
            {selected ? <span className='text-lite-black overflow-hidden whitespace-nowrap me-3 text-ellipsis'>{(selected === 'numeric' || selected === 'string') ? `${selected} check` : selected}</span>
              : <span className='text-lite-grey-3 overflow-hidden whitespace-nowrap me-3 text-ellipsis'>{placeholder}</span>}
          </>
        }
        <img src={downArrow} alt="down-arrow" />
      </div>
      <ul className={`border border-lite-grey rounded-md mt-1 ${width} z-10 absolute bg-white pt-1 pb-2 max-h-80 overflow-y-auto ${dropdownOpen ? 'block' : 'hidden'}`}>
        <>
          {options?.map((option, index) => (
            <li key={index} className='text-sm px-2.5 py-1 overflow-hidden text-ellipsis text-lite-black whitespace-nowrap hover:bg-lite-grey-1' onClick={() => handleSelection(option)}>{option.group_name ? option.group_name : (option === 'numeric' || option === 'string') ? `${option} check` : option === 'Greater Than or Equalto' ? 'Greater Than or Equal To' : option === 'Less Than or Equalto' ? 'Less Than or Equal To' : option}</li>
          ))}
        </>
      </ul>
    </div>
  )
}

export default Dropdown
