import React, { useEffect, useState } from 'react';
import WorkflowStepper from '../ReUseable/Stepper/WorkflowStepper';
import Preview from './Preview/Preview';

const Workflow = () => {
  const [previewStepper, setPreviewStepper] = useState(false);
  const [dataValidationStepper, setDataValidationStepper] = useState(false);
  const [autoMappingStepper, setAutoMappingStepper] = useState(false);
  const [reviewStepper, setReviewStepper] = useState(false);
  const [destinationStepper, setDestinationStepper] = useState(false);
  const [deleteModalOpen, setDeleteModalOpen] = useState(false);

  useEffect(() => {
    setPreviewStepper(false);
    setDataValidationStepper(false);
    setAutoMappingStepper(false);
    setReviewStepper(false);
  }, [])

  return (  
      <div className='mt-16 px-5 ms-[50px] py-1'>
        <WorkflowStepper previewStepper={previewStepper} dataValidationStepper={dataValidationStepper} autoMappingStepper={autoMappingStepper} reviewStepper={reviewStepper} destinationStepper={destinationStepper} />
        <Preview setPreviewStepper={setPreviewStepper} setDataValidationStepper={setDataValidationStepper} setAutoMappingStepper={setAutoMappingStepper} setReviewStepper={setReviewStepper} dataValidationStepper={dataValidationStepper} setDeleteModalOpen={setDeleteModalOpen} deleteModalOpen={deleteModalOpen} setDestinationStepper={setDestinationStepper} />
      </div>   
  )
}

export default Workflow